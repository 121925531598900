<template>
  <div class="container-fliud">
    <Header></Header>
    <h4 class="faq">Activity Map</h4>
<div class="jvmap-smart" id="world-map">


</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
window.jQuery = require('jquery');
var $ = window.jQuery;
require('jvectormap');
require('../../public/jquery-jvectormap-africa-mill.js');

import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'


export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
  data() {
    return {
      activitylaglng:[],
     
    }
  },
   mounted(){
        this.activityMap()
    },
    methods:{
        activityMap(){
          baseUrl.get('/api/getAllActivityMap')
          .then((res)=>{

            const Makers = res.data.map(el =>{
              return{
                latLng:[el.latitude, el.longitude],
                name:el.country
              }
            })
         

           
              var gdpData = {
            "ET":50.97,
            "CM":50.97,
            "GH":50.97,
            "MG":50.97
            };
            
            $('#world-map').vectorMap({
                map: 'africa_mill', 
                 zoomOnScroll:false,
                series:{
                    regions:[{
                        values:gdpData,
                        scale:['#FFFFFF', '#98C149'],
                        normalizeFunction:'polynomial'
                    }],
                },
                  onRegionTipShow: function(e, el){
                    el.html(el.html());
                  },
                    onRegionClick: function(event, code){
                          if(code == 'GH'){
                             window.location.href = "/country/Ghana" 
                          } else if(code == 'CM'){
                             window.location.href = "/country/Cameroon" 
                          }else if(code == 'ET'){
                             window.location.href = "/country/Ethiopia" 
                          }
                          else if(code == 'MG'){
                             window.location.href = "/country/Madagascar" 
                          }
                        },
                    markerStyle: {
                    initial: {
                      fill: '#F8E23B',
                      stroke: '#383f47'
                    }
            },
        backgroundColor: '#383f47',
        markers:Makers
            });
        
           
          })
        }
       
    }
}
</script>
<style scoped>
@import '../../public/jquery-jvectormap-2.0.5.css';
#world-map{
height: 120vh;
}
.faq{
margin-top: 160px;
color: #97c149;
padding: 10px;
margin-left: 50px;
}
</style>